import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, MenuItem, Box, Drawer } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme

const NavBar = () => {
  const theme = useTheme(); // Access the theme
  const [isMobile, setIsMobile] = useState(false); // State to track if the screen is mobile-sized
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage the drawer (hamburger menu)

  useEffect(() => {
    // Check the screen width and set isMobile accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItems = ['Skills', 'Certifications', 'Experience', 'Education', 'Portfolio', 'Contact'];

  // Function to toggle the drawer open/close
  const handleToggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography 
          variant="h6" 
          component={Link} 
          to="/" 
          sx={{ 
            color: 'white', 
            textDecoration: 'none', 
            fontWeight: 'bold' 
          }} // Make name bold
          aria-label="Homepage" // Accessibility label
        >
          Jarett Elser
        </Typography>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
          {isMobile ? (
            <div>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Open menu" // Accessibility label
                onClick={handleToggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleToggleDrawer(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    backgroundColor: theme.palette.primary.main, // Use theme color for background
                    color: theme.palette.common.white, // Use theme color for text
                    width: '250px',
                  },
                }}
              >
                <Box
                  sx={{ width: 250, padding: '20px', display: 'flex', flexDirection: 'column' }}
                  role="presentation"
                  onClick={handleToggleDrawer(false)}
                  onKeyDown={handleToggleDrawer(false)}
                >
                  {menuItems.map((text, index) => (
                    <MenuItem
                      key={index}
                      component={Link}
                      to={`/${text.toLowerCase()}`}
                      sx={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: 'white',
                        position: 'relative',
                        '&:hover::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: -2,
                          left: 0,
                          right: 0,
                          height: '2px',
                          backgroundColor: '#ffffff', // White underline
                          transition: 'all 0.3s ease-in-out',
                        },
                      }}
                      aria-label={text} // Accessibility label for each menu item
                    >
                      {text}
                    </MenuItem>
                  ))}
                </Box>
              </Drawer>
            </div>
          ) : (
            <div style={{ display: 'flex', gap: '16px' }}>
              {menuItems.map((text, index) => (
                <MenuItem
                  key={index}
                  component={Link}
                  to={`/${text.toLowerCase()}`}
                  sx={{
                    position: 'relative',
                    '&:hover::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -2,
                      left: 0,
                      right: 0,
                      height: '2px',
                      backgroundColor: '#ffffff', // White underline
                      transition: 'all 0.3s ease-in-out',
                    },
                  }}
                  aria-label={text} // Accessibility label for each menu item
                >
                  {text}
                </MenuItem>
              ))}
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar; // Export the NavBar component