import React from 'react';
import { IconButton } from '@mui/material'; // Importing IconButton from Material-UI
import { Brightness4, Brightness7 } from '@mui/icons-material'; // Importing icons for light and dark modes
import { useTheme } from '@mui/material/styles'; // Importing useTheme to access the current theme

const DarkModeToggle = ({ toggleDarkMode }) => {
  const theme = useTheme(); // Access the current theme

  return (
    <IconButton 
      onClick={toggleDarkMode} // Trigger the toggle function on click
      sx={{
        position: 'fixed', // Fix position to stay in the same place when scrolling
        bottom: '16px', // Position from the bottom
        left: '16px', // Position from the left
        backgroundColor: theme.palette.background.paper, // Use the paper background color from the theme
        borderRadius: '50%', // Make the button round
        boxShadow: 3, // Add a shadow for depth
        '&:hover': {
          boxShadow: 6, // Increase shadow on hover for a subtle effect
        },
      }}
    >
      {/* 
        Render the appropriate icon based on the current theme:
        - Brightness7 (sun icon) for dark mode (indicating it can switch to light mode)
        - Brightness4 (moon icon) for light mode (indicating it can switch to dark mode)
      */}
      {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
  );
};

export default DarkModeToggle; // Export the DarkModeToggle component for use in other files