import { createTheme } from '@mui/material/styles';

// Create a function that generates the theme based on the mode
const theme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light', // Switch between dark and light modes
    primary: {
      main: darkMode ? '#90caf9' : '#1976d2', // Primary color for dark/light mode
    },
    secondary: {
      main: darkMode ? '#f48fb1' : '#dc004e', // Secondary color for dark/light mode
    },
    background: {
      default: darkMode ? '#121212' : '#f0f0f0', // Background color for dark/light mode
      paper: darkMode ? '#1e1e1e' : '#f5f5f5', // Paper color for dark/light mode
    },
  },
});

export default theme;