// Importing React library, which is necessary to write React components
import React, { Suspense, lazy, useState } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material'; // Added CssBaseline for consistent baseline
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import theme from './Theme.js'; // Import your theme file
import DarkModeToggle from './DarkModeToggle'; // Import the DarkModeToggle component

// Lazy loading the components
const MainPage = lazy(() => import('./MainPage'));
const BioSection = lazy(() => import('./BioSection'));
const Certifications = lazy(() => import('./Certifications'));
const Experience = lazy(() => import('./Experience'));
const Education = lazy(() => import('./Education'));
const Skills = lazy(() => import('./Skills'));
const Contact = lazy(() => import('./Contact'));

const Portfolio = lazy(() => import('./Portfolio'));
const DodgeTheProjectiles = lazy(() => import('./Portfolio/DodgeTheProjectiles'));
const Craftbevia = lazy(() => import('./Portfolio/Craftbevia'));
const ElectricLongboard = lazy(() => import('./Portfolio/ElectricLongboard'));
const BusinessCard = lazy(() => import('./Portfolio/BusinessCard'));
const PictureFrame = lazy(() => import('./Portfolio/PictureFrame'));

function ProjectPage() {
  const { projectName } = useParams();
  
  switch (projectName) {
    case 'dodgeTheProjectiles':
      return <DodgeTheProjectiles />;
    case 'craftbevia':
      return <Craftbevia />;
    case 'electricLongboard':
      return <ElectricLongboard />;
    case 'businessCard':
      return <BusinessCard />;
    case 'pictureFrame':
      return <PictureFrame />;
    default:
      return <div>Project not found</div>;
  }
}

// 'App' is a functional React component.
const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode((prev) => !prev); // Toggle dark mode state
  };

  return (
    <ThemeProvider theme={theme(darkMode)}> {/* Wrap the app with ThemeProvider */}
      <CssBaseline /> {/* Apply baseline styles, including dark mode */}
      <Router>
        <div className="app-container">
          <header>
            <NavBar />
          </header>
          <main style={{ paddingTop: '64px' }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/bio" element={<BioSection />} />
                <Route path="/certifications" element={<Certifications />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/education" element={<Education />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/portfolio/:projectName" element={<ProjectPage />} />
              </Routes>
            </Suspense>
          </main>
          {/* Dark mode toggle button positioned at the bottom left */}
          <DarkModeToggle toggleDarkMode={toggleDarkMode} /> {/* Use the DarkModeToggle component */}
        </div>
      </Router>
    </ThemeProvider>
  );
}

// Exporting the 'App' component so it can be used in other parts of the application.
export default App;